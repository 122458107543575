/* @flow */
import { connect } from 'react-redux';
import UserGrid from './userGrid.jsx';

import { getDeviceType, getIsBuyer } from '../../../reducers/';

export default connect((state: RootReducerState) => ({
  deviceType: getDeviceType(state),
  isBuyer: getIsBuyer(state),
}))(UserGrid);
