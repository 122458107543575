import { connect } from 'react-redux';
import UserBadge from './userBadge.jsx';

import { getIsBuyer } from '../../../../reducers/';

const mapStateToProps = (state) => ({
  isBuyer: getIsBuyer(state),
});

export default connect(mapStateToProps)(UserBadge);
