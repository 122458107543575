/* @flow */
/* eslint-env browser */
import { useEffect, useCallback, useState, useRef } from 'react';
import qs from 'qs';
import debounce from 'lodash/debounce';
import t from 'counterpart';
import { isEqual } from 'lodash';

import { Box, Flex, Text, Spinner } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import Footer from '../../footer/';
import RowGrid from '../shared/rowGrid/';
import Pagination from './pagination/';
import PromoBanner from '../../promoBanner';

import {
  levelReached,
  cleanObject,
  isOneOf,
  isLastPage,
  hasNextPageLoaded,
  isPaginatablePending,
  reachedMaxPageOffset,
} from '../../../../helpers/tools';

import {
  PAGINATION_PARADIGM_AUTO,
  PAGINATION_PARADIGM_MANUAL,
  PAGINATION_PARADIGM_SHOW_MORE,
  PAGINATION_MAX_PAGE,
  TEMP_FIELD_CONVERSION_EVENT_OPTION,
} from '../../../../constants/misc';

type PaginatablePhotoGridProps = {
  actionBar: string,
  className: string,
  contextPaginatableMetadata: {
    scrollTop: number,
    path: string,
  } & ContextPaginatableMetadataState,
  conversionEventOption: ConversionEventOption,
  endpointParams: Object, // TODO: add typing, `Object` is an alias for `any`
  feedName: string, // Name to show on top of context belt
  fetchPaginatable: Function, // TODO: add typing, `Function` is an alias for `any`
  initialUrl: string,
  isOnSearch: boolean,
  noFooter: boolean,
  noTimestamp: boolean, // whether to show photocard timestamp
  page: number,
  paginatable: Paginatable,
  paginationParadigm: 'auto' | 'manual', // render a "Show more" button rather than paging on scroll
  setContextPaginatableMetadata: Function, // TODO: add typing, `Function` is an alias for `any`
  setTempField: Function, // TODO: add typing, `Function` is an alias for `any`
  shouldShowCaptions: boolean,
  stepSize: number,
  hasNoSearchTerm?: boolean,
};

const PaginatablePhotoGrid = ({
  actionBar,
  className,
  contextPaginatableMetadata,
  conversionEventOption,
  endpointParams,
  feedName,
  fetchPaginatable,
  initialUrl,
  isOnSearch,
  noFooter,
  noTimestamp,
  page,
  paginatable,
  paginationParadigm,
  setContextPaginatableMetadata,
  setTempField,
  shouldShowCaptions,
  stepSize,
  hasNoSearchTerm,
}: PaginatablePhotoGridProps) => {
  const [added, setAdded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const endpointParamsRef = useRef(endpointParams);

  if (!isEqual(endpointParamsRef.current, endpointParams)) {
    endpointParamsRef.current = endpointParams;
  }

  const paginatableRef = useRef(paginatable);

  if (!isEqual(paginatableRef.current, paginatable)) {
    paginatableRef.current = paginatable;
  }

  const hasManualPagination = useCallback(
    () => paginationParadigm === PAGINATION_PARADIGM_MANUAL,
    [paginationParadigm]
  );

  const getIsLastPage = useCallback(
    () => isLastPage(paginatableRef.current),
    []
  );

  const getPage = useCallback(
    () => parseInt(page, 10) || paginatable.initialPage || 1,
    [page, paginatable.initialPage]
  );
  const shouldLoadMorePhotos = useCallback(
    () =>
      hasManualPagination()
        ? !reachedMaxPageOffset(paginatableRef.current) &&
          !isPaginatablePending(paginatableRef.current) &&
          !getIsLastPage() &&
          !hasNextPageLoaded(paginatableRef.current, stepSize, getPage())
        : !getIsLastPage(),
    [getIsLastPage, getPage, hasManualPagination, stepSize]
  );

  const isReturningToExistingPaginatable = useCallback(
    () => contextPaginatableMetadata?.path === initialUrl,
    [initialUrl, contextPaginatableMetadata?.path]
  );

  const openOverlay = (payload: {}) => {
    const initialUrlSplit = initialUrl.split('?');
    const pathObject = {
      ...qs.parse(initialUrlSplit[1]),
      page: getPage() > 1 ? getPage() : undefined,
    };

    const path = hasManualPagination()
      ? `${initialUrlSplit[0]}${qs.stringify(pathObject, {
          addQueryPrefix: true,
        })}`
      : initialUrl;

    setContextPaginatableMetadata({
      ...payload,
      scrollTop: window.pageYOffset || document.documentElement?.scrollTop,
      path,
      resourceType: paginatable.resourceType,
      resourceId: paginatable.resourceId,
      paginatableName: paginatable.paginatableName,
      page: getPage(),
      endpointParams,
      feedName,
      stepSize: stepSize,
    });
  };

  const setConversionEventOption = useCallback(
    (
      {
        resourceType,
        resourceId,
        paginatableName,
      }: {
        resourceType: string,
        resourceId: string | number,
        paginatableName: string,
      },
      conversionEventOption,
      endpointParams
    ) => {
      return setTempField({
        name: TEMP_FIELD_CONVERSION_EVENT_OPTION,
        value: cleanObject({
          sourceType: resourceType,
          sourceId: resourceType !== 'search' && resourceId,
          sourceName: resourceType !== 'search' && paginatableName,
          ...endpointParams,
          ...conversionEventOption,
        }),
      });
    },
    [setTempField]
  );

  const hasShowMorePagination = () =>
    paginationParadigm === PAGINATION_PARADIGM_SHOW_MORE;

  const shouldShowUser = () =>
    !(
      paginatable &&
      paginatable.resourceType === 'user' &&
      isOneOf(paginatable && paginatable.paginatableName, [
        'illustrations',
        'photos',
        'marketPhotos',
        'partnerPhotos',
        'releasesNeededPhotos',
      ])
    );

  const shouldShowEndoGridIcon = () =>
    getIsLastPage() && !hasManualPagination();

  const pageOffset = () => (getPage() - 1) * stepSize;

  const getAssets = () => {
    if (pageOffset() > 0) {
      return paginatable.items.slice(0, stepSize);
    }

    return (
      paginatable &&
      (hasManualPagination()
        ? paginatable.items.slice(pageOffset(), pageOffset() + stepSize)
        : paginatable.items)
    );
  };

  useEffect(() => {
    const addPhotos = () => {
      if (shouldLoadMorePhotos()) {
        fetchPaginatable();
      }
    };
    const scrollListener = debounce(() => {
      if (
        paginationParadigm === PAGINATION_PARADIGM_AUTO &&
        !isPaginatablePending(paginatableRef.current) &&
        levelReached()
      ) {
        return addPhotos();
      }
      if (
        PAGINATION_PARADIGM_MANUAL !== paginationParadigm &&
        !isPaginatablePending(paginatableRef.current) &&
        levelReached() &&
        !added
      ) {
        addPhotos();
        setAdded(true);
      }
    }, 150);

    document.addEventListener('scroll', scrollListener);

    setConversionEventOption(
      paginatableRef.current,
      conversionEventOption,
      endpointParamsRef.current
    );

    return () => document.removeEventListener('scroll', scrollListener);
  }, [
    added,
    contextPaginatableMetadata?.scrollTop,
    conversionEventOption,
    isReturningToExistingPaginatable,
    fetchPaginatable,
    paginationParadigm,
    setConversionEventOption,
    shouldLoadMorePhotos,
  ]);

  useEffect(
    // not sure about this, however, it didn't update too often while testing.
    () => {
      // if we've come from the overlay
      if (paginatable.items && !hasScrolled) {
        if (isReturningToExistingPaginatable()) {
          window.scrollTo(0, contextPaginatableMetadata?.scrollTop);

          setHasScrolled(true);
        }
      }
    },

    [
      paginatable.items,
      isReturningToExistingPaginatable,
      hasScrolled,
      contextPaginatableMetadata?.scrollTop,
    ]
  );

  useEffect(() => {
    setAdded(false);
  }, [page]);

  useEffect(() => {
    setConversionEventOption(
      {
        resourceId: paginatable.resourceId,
        resourceType: paginatable.resourceType,
        paginatableName: paginatable.paginatableName,
      },
      conversionEventOption,
      endpointParamsRef.current
    );
  }, [
    conversionEventOption,
    paginatable.resourceId,
    paginatable.resourceType,
    paginatable.paginatableName,
    setConversionEventOption,
  ]);

  return (
    <div className={className}>
      <PromoBanner />
      {!isPaginatablePending(paginatable) && !paginatable.items?.length ? (
        <Box pl={2}>
          <Text color="grey50">{!hasNoSearchTerm && t('grid.noResults')}</Text>
        </Box>
      ) : (
        <>
          <RowGrid
            shouldShowCaptions={shouldShowCaptions}
            openOverlay={openOverlay}
            noTimestamp={noTimestamp}
            isLastPage={getIsLastPage()}
            hideLastRow={!getIsLastPage() && !hasManualPagination()}
            resourceType={paginatable?.resourceType}
            showUser={shouldShowUser()}
            positionOffset={pageOffset()}
            actionBar={actionBar}
            assets={getAssets()}
          />
          {!getIsLastPage() &&
            !hasManualPagination() &&
            !hasShowMorePagination() &&
            isPaginatablePending(paginatable) && (
              <ThemeProvider>
                <Flex justifyContent="center" p="3">
                  <Spinner inline />
                </Flex>
              </ThemeProvider>
            )}
          {hasManualPagination() && (
            <Pagination
              totalPages={Math.min(
                PAGINATION_MAX_PAGE,
                Math.ceil(paginatable.total / stepSize)
              )}
              isOnSearch={isOnSearch}
              initialUrl={initialUrl}
            />
          )}
          {shouldShowEndoGridIcon() && (
            <div className="text-centered pgrid_endOfStream">
              <img
                alt="end of photo grid"
                src="/node-static/img/filmroll.svg"
              />
            </div>
          )}
          {hasManualPagination() && !noFooter && <Footer />}
        </>
      )}
    </div>
  );
};

export default PaginatablePhotoGrid;
