/* @flow */
/* eslint-env browser */
import { Component } from 'react';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'redux';
import { InView } from 'react-intersection-observer';
import { Flex, Spinner } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import UserColumn from './userColumn.jsx';
import { GET_ALBUM_USERS } from '../../../graphql/queries/album';
import {
  GET_USER_FOLLOWERS,
  GET_USER_FRIENDS,
} from '../../../graphql/queries/user';

import { deepMergePaginatables } from '../../../helpers/tools';

import {
  DEVICE_TYPE_PHONE,
  DEVICE_TYPE_TABLET,
  DEVICE_TYPE_DESKTOP,
  USER_GRID_STEP_SIZE,
} from '../../../constants/misc';

const numberOfColumns = {
  [DEVICE_TYPE_PHONE]: 1,
  [DEVICE_TYPE_TABLET]: 2,
  [DEVICE_TYPE_DESKTOP]: 3,
};

const paginationTypes = {
  FOLLOWINGS: 'followings',
  FOLLOWERS: 'followers',
  CONTRIBUTORS: 'contributors',
};

type Props = {
  deviceType: DeviceType,
  resourceId: number,
  isBuyer: boolean,
  paginatableName: string,
  data: { user: EyeEmUser, album: EyeEmAlbum, loading: boolean },
};

type State = { isLoadingMore: boolean };

class UserGrid extends Component<Props, State> {
  page = 1;

  state = {
    isLoadingMore: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.paginatableName !== this.props.paginatableName) {
      this.page = 1;
    }
  };

  handleObserver = (inView) => {
    const items =
      get(this.props, 'data.album.contributors.items') ||
      get(this.props, 'data.user.followers.items') ||
      get(this.props, 'data.user.friends.items');

    const roundedItemsDividedByStepSize = Math.round(
      items.length / USER_GRID_STEP_SIZE
    );

    if (inView && this.page === roundedItemsDividedByStepSize) {
      this.page = roundedItemsDividedByStepSize + 1;
      this.addUsers();
    }
  };

  addUsers = () => {
    const { data, paginatableName, isBuyer } = this.props;
    const gridData =
      data.album?.contributors || data.user?.followers || data.user?.friends;

    if (gridData.items.length < gridData.total) {
      // Do apollo query

      const offset = (this.page - 1) * USER_GRID_STEP_SIZE;
      this.setState({ isLoadingMore: true });

      data
        .fetchMore({
          variables: {
            offset,
            paginatableName,
            isBuyer,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;

            return Object.assign(
              {},
              deepMergePaginatables(prev, fetchMoreResult)
            );
          },
        })
        .finally(() => {
          this.setState({ isLoadingMore: false });
        });
    }
  };

  render() {
    const showSpinner = this.props.data?.loading || this.state.isLoadingMore;

    const columns = [];
    const { album, user, loading } = this.props.data;

    if ((album || user?.followers || user?.friends) && !loading) {
      const slicedUsers =
        album?.contributors?.items ||
        user?.followers?.items ||
        user?.friends?.items;

      for (let i = 0; i < numberOfColumns[this.props.deviceType]; i += 1) {
        columns.push(
          <UserColumn
            key={`column${i}`}
            slicedUsers={slicedUsers}
            numberOfColumns={numberOfColumns[this.props.deviceType]}
            columnNumber={i}
          />
        );
      }
    }

    return (
      <div className="grid-topMargin userGrid">
        {columns.length > 0 && (
          <>
            <div className="row">{columns}</div>
            <InView
              as="div"
              rootMargin={'0px 0px 600px 0px'}
              onChange={this.handleObserver}></InView>
          </>
        )}
        {showSpinner && (
          <ThemeProvider>
            <Flex justifyContent="center" p="3">
              <Spinner inline />
            </Flex>
          </ThemeProvider>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(GET_ALBUM_USERS, {
    skip: ({ paginatableName }) =>
      paginatableName !== paginationTypes.CONTRIBUTORS,
    options: ({ resourceId, isBuyer }) => ({
      variables: {
        offset: 0,
        albumId: resourceId,
        isBuyer,
      },
    }),
  }),
  graphql(GET_USER_FOLLOWERS, {
    skip: ({ paginatableName }) =>
      paginatableName !== paginationTypes.FOLLOWERS,
    options: ({ resourceId, paginatableName, user }) => ({
      variables: {
        id: user.id,
        offset: 0,
        nickname: resourceId,
        paginatableName,
      },
    }),
  }),
  graphql(GET_USER_FRIENDS, {
    skip: ({ paginatableName }) =>
      paginatableName !== paginationTypes.FOLLOWINGS,
    options: ({ resourceId, paginatableName, user }) => ({
      variables: {
        id: user.id,
        offset: 0,
        nickname: resourceId,
        paginatableName,
      },
    }),
  })
)(UserGrid);
