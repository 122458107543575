/* @flow */
// Libraries
import type { Node } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex, Box, Text } from '@eyeem-ui/atoms';
import { media, ThemeProvider } from '@eyeem-ui/theme';

// General Components
import PhotoFullWidth from './photoFullWidth/';
import Photo from './photo/';

import { getIsEnterpriseCustomer } from '../../reducers/';

const StyledButtonWrapper = styled.div`
  margin-top: 20px;
  @media ${media.largeUp} {
    display: none;
  }
`;

const StyledPhotoFullWidth = styled(PhotoFullWidth)`
  margin-top: ${(props) =>
    props.isEnterprise ? `calc(-${props.theme.navHeight})` : null};

  @media ${media.mediumUp} {
    margin-top: -${(props) => props.theme.navHeight};
  }
`;

const StyledHeaderButtonWrapper = styled.span`
  margin-top: 20px;
  @media ${media.mediumUp} {
    position: absolute;
    right: 32px;
    bottom: 32px;
    margin-top: 0;
  }
`;

function Header(props: {
  photoUrl: string,
  validResource: boolean,
  thumbUrl?: string,
  headline?: Node,
  subline?: string | Node,
  bigBottomTitle?: string,
  button?: boolean | Node,
  children?: Node,
  backgroundPosition?: string,
  isEnterprise: boolean,
  photoAlt: string,
}) {
  return (
    <StyledPhotoFullWidth
      isEnterprise={props.isEnterprise}
      className="header"
      url={props.photoUrl}
      backgroundPosition={props.backgroundPosition}
      alt={props.photoAlt}>
      <div className="header_content">
        {props.validResource && (
          <div className="header_content_main">
            <div className="header_content_main_center">
              <Flex
                flexDirection={['column', 'column', 'row']}
                alignItems="center">
                {props.thumbUrl && (
                  <Photo
                    className="header_content_main_thumb"
                    size1="circle"
                    size2={104}
                    url={props.thumbUrl}
                  />
                )}
                <Box>
                  {props.headline}
                  {props.subline && typeof props.subline === 'string' && (
                    <div>
                      <h2 className="typo_subHeader typo-noMargin">
                        <span className="typo_subHeader typo-noMargin typo-color-white typo-inlineBlock typo-topMarginBoost-xsmall">
                          {props.subline}
                        </span>
                      </h2>
                    </div>
                  )}
                  {props.subline &&
                    typeof props.subline !== 'string' &&
                    props.subline}
                  {props.bigBottomTitle && (
                    <ThemeProvider>
                      <Text as="h1" variant="title2" color="white">
                        {props.bigBottomTitle}
                      </Text>
                    </ThemeProvider>
                  )}
                </Box>
                <StyledButtonWrapper>{props.button}</StyledButtonWrapper>
              </Flex>
            </div>
          </div>
        )}
        <span className="show-for-large-up">
          <StyledHeaderButtonWrapper>{props.button}</StyledHeaderButtonWrapper>
        </span>
        {props.children}
      </div>
    </StyledPhotoFullWidth>
  );
}

const mapStateToProps = (state) => ({
  isEnterprise: getIsEnterpriseCustomer(state),
});

export default connect(mapStateToProps)(Header);
