/* @flow */

import Photo from '../../photo/';
import UserFollowBtn from '../../userFollowBtn/';
import Link from '../../link/';
import T from '../../translate.jsx';

import { TEST_USERPHOTO_CONTAINER } from '../../../../constants/pageObjectSelectors';

function UserBadge(props: { user: EyeEmUser, isBuyer?: boolean }) {
  const { user } = props;
  return user ? (
    <Photo
      className="userBadge text-centered"
      url={user.coverPhoto && user.coverPhoto.previewUrl}
      size1="square"
      size2={400}>
      <div
        className="userBadge_content"
        data-test-id={TEST_USERPHOTO_CONTAINER}>
        <div className="userBadge_content_thumb">
          <Photo
            size1="circle"
            size2={80}
            url={user.thumbUrl}
            link={`/u/${user.nickname.toLowerCase()}`}
          />
        </div>
        <Link
          className="typo_headline3 userBadge_content_headline g_link g_link-white"
          href={`/u/${user.nickname.toLowerCase()}`}>
          {user.fullname || /* \u00A0 === &nbsp; */ '\u00A0'}
        </Link>
        <Link
          className="typo_p typo-inlineBlock g_link g_link-onDark"
          href={`/u/${user.nickname.toLowerCase()}`}>
          @{user.nickname}
        </Link>
        {user.follower && (
          <T className="g_followsYou">photographerBox.follows</T>
        )}
        <ul className="row userBadge_content_counterList">
          <li className="small-4 columns">
            <span className="userBadge_content_counterList_count">
              {user.totalPhotos}
            </span>
            <T
              className="userBadge_content_counterList_label"
              with={{
                count: user.totalPhotos || 0,
              }}>
              userlist.photos
            </T>
          </li>
          <li className="small-4 columns">
            <span className="userBadge_content_counterList_count">
              {user.totalFollowers}
            </span>
            <T
              className="userBadge_content_counterList_label"
              with={{
                count: user.totalFollowers || 0,
              }}>
              userlist.followers
            </T>
          </li>
          <li className="small-4 columns">
            <span className="userBadge_content_counterList_count">
              {user.totalFriends}
            </span>
            <T
              className="userBadge_content_counterList_label"
              with={{
                count: user.totalFriends || 0,
              }}>
              userlist.following
            </T>
          </li>
        </ul>
        {!props.isBuyer && (
          <UserFollowBtn
            fullWidth
            eventPosition="usergrid"
            className="userBadge_content_followBtn"
            user={user}
          />
        )}
      </div>
    </Photo>
  ) : null;
}

export default UserBadge;
