/** @flow */
/** @jsxRuntime classic */
/** @jsx jsx */
import t from 'counterpart';
import { jsx } from 'theme-ui'; // eslint-disable-line
import { Flex, Box, Text, Button } from '@eyeem-ui/atoms';
import { ThemeProvider } from '@eyeem-ui/theme';

import T from '../general/translate.jsx';
import Link from '../general/link/';

import { isLinkExternal } from '../../helpers/tools';
import { NAVBAR_HEIGHT } from '../../constants/misc';

function EmptyState(props: {
  translationPrefix: string,
  noSubline?: boolean,
  noSublineLink?: boolean,
  with?: {},

  // used on profile empty states only
  name?: string,
  button?: boolean,
  withIcon?: boolean,
  resourceId?: string,
  fullHeight?: boolean,
}) {
  return (
    <ThemeProvider>
      <Flex
        sx={{
          height: props.fullHeight
            ? `calc(100vh - ${NAVBAR_HEIGHT * 2}px) !important`
            : 'auto',
          minHeight: 'auto !important',
        }}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p="3">
        <Box width={[1, 2 / 3, 1 / 2]} sx={{ textAlign: 'center' }}>
          {props.withIcon && (
            <Box mb="3">
              <img
                className="emptyStateIcon"
                alt={props.name}
                src={`/node-static/img/emptyStates/${t(
                  `${props.translationPrefix}.icon`
                )}.svg`}
              />
            </Box>
          )}
          <Box mb="2">
            <Text variant="title4">
              {t(`${props.translationPrefix}.headline`, { name: props.name })}
            </Text>
          </Box>
          {!props.noSubline && (
            <Box sx={{ textAlign: 'center' }} mb="3">
              <T
                component={Text}
                color="grey50"
                with={{
                  name: props.name,
                  link: !props.noSublineLink ? (
                    <T
                      component={
                        isLinkExternal(
                          t(`${props.translationPrefix}.link.href`)
                        )
                          ? 'a'
                          : Link
                      }
                      href={t(`${props.translationPrefix}.link.href`)}
                      className="g_link g_link-underlined">
                      {props.translationPrefix}.link.label
                    </T>
                  ) : undefined,
                  ...props.with,
                }}>
                {props.translationPrefix}.subline
              </T>
            </Box>
          )}
          {props.button && (
            <Link
              component={
                isLinkExternal(
                  t(`${props.translationPrefix}.button.href`, {
                    resourceId: props.resourceId,
                  })
                )
                  ? 'a'
                  : Link
              }
              href={t(`${props.translationPrefix}.button.href`, {
                resourceId: props.resourceId,
              })}>
              <Button>{t(`${props.translationPrefix}.button.label`)}</Button>
            </Link>
          )}
        </Box>
      </Flex>
    </ThemeProvider>
  );
}

EmptyState.defaultProps = {
  with: {},
  noSubline: false,
  noSublineLink: false,
  name: undefined,
  button: undefined,
  resourceId: undefined,
  withIcon: true,
  fullHeight: false,
};

export default EmptyState;
