/* @flow */
import UserBadge from './userBadge/';

function UserColumn(props: {
  columnNumber: number,
  numberOfColumns: number,
  slicedUsers: $ReadOnlyArray<EyeEmUser>,
}) {
  return (
    <div className={`small-${12 / props.numberOfColumns} columns`}>
      {props.slicedUsers
        ? props.slicedUsers.map((user, i) => {
            if (i % props.numberOfColumns === props.columnNumber) {
              return <UserBadge key={`badge-${user.id}`} user={user} />;
            }
            return null;
          })
        : null}
    </div>
  );
}

export default UserColumn;
