/* @flow */
import { connect } from 'react-redux';
import { compose } from 'redux';

import { GRID_STEP_SIZE } from '../../../../constants/misc';
import { getDefaultPaginationParadigm } from '../../../../helpers/tools';
import withRouter from '../../../../helpers/hoc/withRouter';
import {
  setContextPaginatableMetadata,
  setPage,
  setTempField,
  updateBrowserInfo,
} from '../../../../helpers/actionCreators/redux';
import { searchTermFromUrl } from '../../../../helpers/search';

import {
  getAuthUser,
  getContextPaginatableMetadata,
  getMaxDeviceWidth,
  getStepSize,
} from '../../../../reducers/';

import PhotoGrid from './paginatableGrid.jsx';

const mapStateToProps = (state, ownProps) => ({
  contextPaginatableMetadata: getContextPaginatableMetadata(state),
  isOnSearch: !!searchTermFromUrl(ownProps.getPath()),
  maxDeviceWidth: getMaxDeviceWidth(state),
  paginationParadigm:
    ownProps.paginationParadigm ||
    getDefaultPaginationParadigm(getAuthUser(state)),
  stepSize:
    ownProps.stepSize ||
    (ownProps.paginationParadigm &&
      GRID_STEP_SIZE[ownProps.paginationParadigm]) ||
    getStepSize(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    setContextPaginatableMetadata: (payload) =>
      dispatch(setContextPaginatableMetadata(payload)),
    setPage: (payload) => dispatch(setPage(payload)),
    setTempField: (payload) => dispatch(setTempField(payload)),
    updateBrowserInfo: (payload) => dispatch(updateBrowserInfo(payload)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PhotoGrid);
